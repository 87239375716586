// extracted by mini-css-extract-plugin
export var weather = "style-module--weather--dveRg";
export var weatherContent = "style-module--weather-content--vZf5j";
export var weatherIconHolder = "style-module--weather-icon-holder--pf-5D";
export var locationHolder = "style-module--location-holder--Y-kya";
export var location = "style-module--location--nHGFq";
export var sentenceHolder = "style-module--sentence-holder--bPcfq";
export var sentence = "style-module--sentence--iB+sn";
export var drunkFeelsLikeHolder = "style-module--drunk-feels-like-holder--joYw9";
export var drunkFeelsLike = "style-module--drunk-feels-like--Lrknd";
export var drunkFeelsLikeNumber = "style-module--drunk-feels-like-number--VHojG";
export var drunkFeelsLikeUnit = "style-module--drunk-feels-like-unit--zDmOn";